import { useGeneralStore } from "~/stores/generalStore";
import { useStagePlay } from "vue-stage-play";

export const useAccount = () => {
  const generalStore = useGeneralStore();
  const user = useSupabaseUser();
  const loading = ref(false);
  const { action } = useStagePlay();

  const initAccount = async () => {
    if (generalStore.account_id !== "" || loading.value) {
      //console.log("Account already initialized");
      return;
    }
    loading.value = true;

    const { data, error } = await useFetch<User>("/api/user", { method: "get" });

    loading.value = false;
    if (error.value) {
      console.log(error.value);
      return;
    }

    if (!data.value) {
      setTimeout(initAccount, 500);
      return;
    }

    const period_end_tmp = data.value?.body.period_end_tmp ?? null;
    const account_id = data.value?.body.account_id ?? "";
    const plan = (data.value?.body.plan as string) ?? "";
    const isAdmin = data.value?.body.isAdmin ?? false;
    const customLimit = Number(data.value?.body?.customLimit) ?? null;

    generalStore.setCustomLimit(customLimit);
    generalStore.setExpirationDate(period_end_tmp);
    generalStore.setAccount(account_id);
    generalStore.setPlan(plan);
    generalStore.discover = true;
    generalStore.isAdmin = isAdmin;

    if (user.value?.user_metadata && !user.value.user_metadata.onboarding)
      return await navigateTo("/onboarding");

    if (data.value?.body.new) {
      await navigateTo("/app/profile");
      action("tour");
    } else {
      location.reload();
    }
  };

  return { loading, initAccount };
};
